import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "D:/a/1/s/kope-docs-user/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const InlineNotification = makeShortcode("InlineNotification");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`Generate and download drawings and models as deliverables for external use.`}</p>
    </PageDescription>
    <p>{`After identifying the best result for your project using KOPE, the Deliverables function allows the export of Drawings and Models generated by the configurator for use in other applications.`}</p>
    <p>{`Access the Deliverables screen through a Saved Result (refer to the `}<a parentName="p" {...{
        "href": "/getting-started/results"
      }}><strong parentName="a">{`Results`}</strong></a>{` section of this guide for details on accessing a Saved Result). `}</p>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/getting-started/37_outputs-deliverables.png",
      "alt": "37_outputs-deliverables.png"
    }}></img>
    <p>{`To generate and download Model and Drawing deliverables, simply click the `}<inlineCode parentName="p">{`Generate Deliverable`}</inlineCode>{` button next to the desired file.`}</p>
    <InlineNotification mdxType="InlineNotification">
Note: A drawing template is required to generate and download the Model and Drawing deliverables, an on-screen pop-up will guide you to the Drawing Template creation screen.
    </InlineNotification>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/getting-started/38_outputs-generate-deliverable-button.png",
      "alt": "38_outputs-generate-deliverable-button.png"
    }}></img>
    <p>{`Clicking the `}<inlineCode parentName="p">{`Generate Deliverable`}</inlineCode>{` button opens the `}<strong parentName="p">{`Settings for new deliverable`}</strong>{` pop-up, allowing you to input basic information before KOPE initiates the deliverable generation.`}</p>
    <p>{`Save your entries by clicking the `}<inlineCode parentName="p">{`Save`}</inlineCode>{` button, and KOPE will handle the task of generating your deliverable.`}</p>
    <p>{`After a brief loading period, the Deliverable will be available for download.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      